<template>
	<section>

		<div class="banner">
            
            <div class="banner-con">
                <div class="event">
                    <div class="banner-title">云商城</div>
                    <div class="banner-pro">助力企业构建私域量进入品牌电商新时代</div>
                    <div class="b-button-box">
                        <div class="edition-price"><span>2980.</span>00/年</div> 
                        <div class="button" @click="payment(
                        )">立即订购</div>
                        <!-- <a href="#detail"><div>功能介绍</div></a>
                        <a href="#case"><div>客户案例</div></a> -->
                        
                    </div>
                </div>
                <img class="bgimg xmall" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/ban_zhsc.png">
        
            </div>
		</div>

        <div class="intro">
            <div class="section">
                <div class="section-content">
                    <div class="con-title">
                        <div>仅需五步、上线您的专属小程序</div>
                        <p>工程师、设计师、平台客服，多对一为您提供全流程服务</p>
                    </div>
                 
                    <div class="intro-part intro-part2">
                        <div class="intro-list">
                            <div class="intro-con">
                                <div class="number">1</div>
                                <div class="intro-i">
                                    <div>注册聚码账号，订购云产品</div>
                                    <p>产品下单前可咨询客服，选择合适您的软件版本</p>
                                </div>
                            </div>
                            <div class="intro-con">
                                <div class="number">2</div>
                                <div class="intro-i">
                                    <div>注册微信商户号，微信小程序</div>
                                    <p>通过微信官网平台注册，可省300元认证费用</p>
                                </div>
                            </div>
                            <div class="intro-con">
                                <div class="number">3</div>
                                <div class="intro-i">
                                    <div>定制化设计您的小程序首页风格</div>
                                    <p>专业设计师与您沟通，设计符合您的产品风格的首页</p>
                                </div>
                            </div>
                            <div class="intro-con">
                                <div class="number">4</div>
                                <div class="intro-i">
                                    <div>上传您的产品，设置价格与运费</div>
                                    <p>客服人员将协助您使用系统对产品进行编辑或调价</p>
                                </div>
                            </div>
                            <div class="intro-con">
                                <div class="number">5</div>
                                <div class="intro-i">
                                    <div>小程序提审，审核通过即可上线</div>
                                    <p>客服人员将协助您设置小程序，以便通过审核并上线</p>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div class="part2m">
                            <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/index.png">
                            <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/item.png">

                        </div>
                    </div>
                    <div class="intro-part intro-part3">
                        <div class="intro-title">
                            <img src="https://oss.ocean.xdangjia.com/dev_gorex/admin/avatar/1631582385541.png">
                            <span>功能介绍</span>
                        </div>
                        <p class="intro-i-operate active">云商城提供多种类型的营销功能供您使用，以面向各类商户的业务需求场景。按您的实际业务场景可选择启用不同的营销活动，搭配基础功能以开展多种形式的营销方案</p>
                        <div class="intro-box active">
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-vip"></i>会员</p>
                                <p class="intro-i-operate">支持多级会员，积分累计或支付年费的形式开通或升级会员</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-217yonghu_jifen"></i>积分</p>
                                <p class="intro-i-operate">积分可抵扣现金使用，可设置多种途径获得积分，购物送积分，评价送积分等</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-youhuiquan"></i>优惠券</p>
                                <p class="intro-i-operate">领券中心可领取各类优惠券，设置使用期限设置包括活动券、会员券等</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-manjian"></i>满减</p>
                                <p class="intro-i-operate">消费满一定金额可自动减免一定金额，例如满100减10，满200减20，满300减30</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-zhekouyouhui"></i>满折</p>
                                <p class="intro-i-operate">消费满一定数量可自动进行一定折扣，例如1件9折，2件8折，3件7折</p>
                            </div>
                        </div>

                    </div>
                    <div class="intro-part intro-part4">
                        <div class="intro-title">
                            <img src="https://oss.ocean.xdangjia.com/dev_gorex/admin/avatar/1631582385541.png">
                            <span>我们的服务</span>
                        </div>
                        <p class="intro-i-operate active">聚码对售后服务非常重视，为确保解决客户在软件使用过程中遇到的问题，我们安排专业工程师与专业设计师全程多对一服务，协助解决各类问题，永久免费提供使用指导</p>
                        <div class="intro-box active">
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-icon2"></i>资深工程师</p>
                                <p class="intro-i-operate">开发团队有丰富的从业经验，在移动互联网领域开发过多个业务类型的项目</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-shejishi1"></i>品牌设计师</p>
                                <p class="intro-i-operate">聚码联合品牌设计师提供设计服务，包括企业运营设计、包装设计、VI设计服务</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-504tongji_ditu"></i>全国连锁服务</p>
                                <p class="intro-i-operate">聚码在全国各大城市设立服务中心，建立品牌服务渠道，提供更便捷的服务</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-mianfei"></i>永久免费指导</p>
                                <p class="intro-i-operate">每个客户设立专项服务群，聚码工程师与设计师直接为客户提供指导服务</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-shizhongnaolingjishixianshi2"></i>定期免费升级</p>
                                <p class="intro-i-operate">聚码SaaS系统将持续不断的进行功能升级与漏洞修复，持续开发最新版本</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-gexingdingzhi"></i>支持功能定制</p>
                                <p class="intro-i-operate">现有功能无法满足客户需求时，提供新功能或新版本的开发定制服务</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-chaxunbaogaoshenqing"></i>协助账号申请</p>
                                <p class="intro-i-operate">聚码系统接入的第三方账号，我们将提供协助客户开通第三方账号的服务</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-shenhedaiban"></i>协助审核上线</p>
                                <p class="intro-i-operate">第三方账号需要审核上线的，我们也将协助客户提供合格的资料顺利通过审核</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title"><i class="iconfont icon-yunying"></i>协助运营设计</p>
                                <p class="intro-i-operate">客户策划营销活动，我们提供营销内容设计服务，包括界面设计、海报设计服务</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
		<div class="section" style="margin-top: 60px;">
			<div class="section-content">
                <div class="part data" id="price">
                    <div class="title-module">
                            <div class="main-title">产品规格</div>
                            <div class="sub-title">
                                <p>为企业搭建一站式营销中心，可轻松</p>
                                <p>对接多个营销小程序</p>
                            </div>
                    </div>
                    <div class="goods-simple">
                        
                        <template v-for="(val,index) in edition">   
                            <div class="goods-simple-col" :key="index">
                                <div class="goods-card-simple">
                                    <div class="simple-card-header">
                                        <div class="simple-header-title">{{val.name}}</div>
                                        <div class="simple-header-desc">{{val.intro}}</div>
                                    </div>
                                    <div class="simple-content-form">
                                        <div class="form-item" v-for="(value,key) in val.function" :key="key">
                                            <div class="form-item-icon">
                                                <i class="el-icon-circle-check active" v-if="value.is_yes==1"></i>
                                                <i class="iconfont icon-yuan1" v-else></i>
                                                
                                            </div>
                                            <div class="form-item-con" :class="{'active':value.is_yes==1}">
                                                <p style="flex-shrink: 0">{{value.function}}</p>
                                                <p v-if="value.is_yes==0">不支持</p>
                                                <p v-else class="active">{{value.amount==0?'支持': value.amount}}</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="simple-price-box ">
                                        <div class="simple-show-label"></div>
                                        <div class="right-price-box">
                                            <div class="price-content">
                                                <span class="price-icon">￥</span>
                                                <span class="price-trade">
                                                    <span class="price-trade-price">
                                                        <span>{{val.pricearr[0]}}</span>
                                                        <span class="trade-price-point">.{{val.pricearr[1]}}</span>
                                                    </span>
                                                </span>
                                                <span class="price-info">/年</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="buy-button-box" data-tracker-type="goodsBuy">
                                        <button @click="payment(val.id)" type="button" class="ace-btn ace-large ace-btn-primary button-action"><span class="ace-btn-helper">立即购买</span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                            
                        </template> 
                        
                    </div>
                </div>


			</div>
		</div>
		
	</section>
</template>
<script type="text/javascript">
	export default {
    data(){
        return {
        	cloudid:'',
        	pagedata:{},
        	detailD: [],
            edition: []
        }
    },
	mounted(){
		console.log('this is partners');
		this.cloudid = this.$route.query.id;
		this.pageinit();
	},
	methods: {
		pageinit(){
			console.log(this.cloudid)
			let _this = this;
			this.axios.post('/cloud/nologin/detail', {
                cloudid: this.cloudid
            })
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {
                    _this.$nextTick(function () {
                        _this.pagedata = res.extra;
                        _this.detailD = res.extra.detail;
                        var edition = res.extra.edition;
                        
                        _this.edition = edition.map((val) => {
                            var price = val.price.split('.');
                            val.pricearr = price;
                            return val;
                        })
                    })
                    
                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},
        payment(id){
            this.$router.push({name: 'product_ordering',query:{id:1,editionid: id}});
        }
	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
		width: 100%;
		min-width: 1024px;
		height: 500px;
        background-color: #0178ff;
		overflow-y: hidden;
	}
	.banner-con{
		width: auto;
		height: 100%;
	    margin-left: auto;
	    margin-right: auto;
	    /*margin-top: 160px;*/
	    max-width: 1374px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

    }
    .bgimg{
        width: auto;
        height: 100%;
        object-position: right;
        position: absolute;
        right: 0;
    }
    .bgimg.xmall{
        width: 714px;
        height: auto;
        padding-top: 50px;
    }
    .event{
		width: 409px;
		height: 100%;
	    display: flex;
    	flex-direction: column;
    	justify-content: center;
	    padding-bottom: 40px;
        padding-left: 30px;
        position: absolute;
        left: 0;
        z-index: 9;
    }
    .banner-title{
    	font-size: 32px;
    	font-weight: bold;
    	color: #fff;
        font-family: 'SimSun';
    }
    .banner-pro{
    	font-size: 20px;
    	color: #fff;
    	padding-top: 4px;
    }
    .b-button-box{
    	width: 100%;
    	display: flex;
    	align-items: center;
    	margin-top: 30px;
        color: #fff;
    }
    .b-button-box a,.b-button-box .button{
    	width: 140px;
    	height: 34px;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	border-radius: 5px;
    	cursor: pointer;
    	font-size: 16px;
    	color: #169BD5;
        margin-left: 30px;
    }
    .b-button-box .button{
    	color: #fff; 
        border: 1px solid #fff;
    }
    .edition-price{
        font-size: 18px;
    }
    .edition-price span{
        font-size: 28px;
    }

    .part-title{
    	font-size: 24px;
    	font-weight: bold;
    	line-height: 24px;
    	color: #333333;
    	margin-top: 80px;
    }
    .list-item{
    	margin-top: 40px;
    }


    .goods-simple{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 60px;
        padding-bottom: 60px;
    }
    .goods-simple-col{
        flex: 0 0 25%;
        width: 25%;
        max-width: 25%;
        padding: 5px 20px;
    }
    .goods-card-simple {
        background: #fff;
        position: relative;
        border: 1px solid #ededec;
        transition: box-shadow .3s ease-out;
    }
    .goods-card-simple:hover {
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    }
    .simple-card-header {
        padding: 0 20px 10px;
        padding-top: 28px;
        box-sizing: border-box;
        background: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/online/online_header.jpeg) center top / 100% no-repeat;
    }
    .simple-header-title {
        position: relative;
        text-align: left;
        font-size: 18px;
        color: #181818;
        letter-spacing: .55px;
        line-height: 30px;
        margin-bottom: 8px;
        font-weight: 600;
        transition: all .3s;
    }
    .simple-header-desc {
        text-align: left;
        font-size: 14px;
        min-height: 48px;
        letter-spacing: .37px;
        line-height: 24px;
        color: #999;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .simple-content-form {
        /*height: 208px;*/
        padding: 8px 20px;
        box-sizing: border-box;
        /*border-bottom: 1px solid #ebecec;*/
    }
    .form-item{
        display: flex;
        align-items: center;
        /*margin-top: 12px;*/
    }
    .form-item-icon{
        width: 40px;
        display: flex;
        align-items: center;
    }
    .form-item-icon i{
        font-size: 18px;
        color: #999;
        flex-shrink: 0;
    }
    .form-item-icon i.active{
        color: #5bd44e;
    }
    .form-item-con{
        height: 32px;
        line-height: 32px;
        flex: 1;
        font-size: 14px;
        color: #999;
        /*border-bottom: 1px solid #ebecec;*/
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .form-item-con.active{
        color: #333;
    }
    .simple-price-box {
        padding: 0 20px;
        box-sizing: border-box;
        border-top: 1px solid #ebecec;
    }
    .simple-show-label {
        margin-top: 12px;
        margin-bottom: 12px;
        height: 20px;
        overflow: hidden;
    }
    .right-price-box {
        margin-bottom: 14px;
        height: 50px;
    }
    .price-content {
        margin-bottom: 4px;
    }
    .price-icon {
        color: #ff6a00;
        font-size: 16px;
        letter-spacing: .81px;
    }
    .price-trade {
        text-align: left;
        color: #ff6a00;
        line-height: 26px;
        font-size: 30px;
        letter-spacing: 1.2px;
    }
    .trade-price-point {
        font-size: 16px;
        letter-spacing: .81px;
    }
    .price-info {
        margin-left: 4px;
        font-size: 14px;
        letter-spacing: .81px;
    }
    .button-action {
        width: 100%;
    }
    .ace-btn.ace-btn-primary, .ace-btn.ace-btn-primary.visited, .ace-btn.ace-btn-primary:link, .ace-btn.ace-btn-primary:visited {
        color: #fff;
    }
    .ace-btn.ace-btn-primary {
        border-style: solid;
        background-color: #ff6a00;
        border-color: transparent;
    }
    .ace-btn.ace-large {
        border-radius: 0;
        padding: 0 36px;
        height: 36px;
        font-size: 14px;
        border-width: 1px;
    }
    .ace-btn.ace-btn-primary:hover {
        color: #fff;
        background-color: #ff791a;
        border-color: #ff791a;
        text-decoration: none;
    }

    
    .data{
        width: 100%;
        border-radius: 30px;
        background-color: #fff;
        padding-top: 60px;
        overflow: hidden;
    }

    .intro{
        width: 100%;
        background-color: #fff;
        padding: 60px 0;
    }
    .intro .section-content{
        padding: 0 80px;
    }
    .intro-part{
        margin-top: 60px;
        padding: 0 24px;
    }

    .intro-title{
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        color: #333;
    }
    .intro-title img{
        width: 34px;
        height: auto;
        margin-right: 12px;
    }
    .intro-box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        color: #333;
    }
    .intro-box.active{
        margin-top: 20px;
    }
    .intro-box.grey{
        color: #909399;
    }
    .intro-item{
        width: 33.33%;
        margin: 20px 0;
        
        padding-right: 40px;
    }
    .intro-item:nth-of-type(3n){
        padding-right: 0;
    }
    .intro-i-title{
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
    .intro-i-title .iconfont{
        font-size: 24px;
        margin-right: 6px;
        color: #333;
        font-weight: 400;
    }
    .intro-i-operate{
        font-size: 14px;
        padding-top: 8px;
    }
    .intro-i-operate.active{
        width: 60%;
    }
    .intro-part2{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        padding-bottom: 12px;
    }
    .intro-part2::after{
        content: ' ';
        width: 120%;
        height: 150px;
        background: #0178ff;
        transform: rotate(-4deg);
        position: absolute;
        left: 1px;
        bottom: -99px;
    }
    .part2m{
        flex-shrink: 0;
        display: flex;
        align-items: flex-end;
        margin-left: 180px;
        position: relative;
        z-index: 9;
    }
    .part2m img:nth-of-type(1){
        width: 260px;
        height: auto;
    }
    .part2m img:nth-of-type(2){
        width: 240px;
        height: auto;
        margin-left: 10px;
    }
   /* .part2m img{
        width: 508px;
        margin-left: 180px;
        margin-right: 12px;
        position: relative;
        z-index: 9;
    }*/
    .intro-list{
        width: 100%;
    }
    .intro-con{
        display: flex;
        align-items: center;
        margin-top: 30px;
    }
    .intro-con .number{
        flex-shrink: 0;
        width: 50px;
        font-size: 24px;
        font-weight: bold;
        position: relative;
        padding-left: 12px;
    }
    .intro-con .number::before{
        content: ' ';
        width: 3px;
        height: 30px;
        background-color: #0178ff;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .intro-i{
        color: #333;
    }
    .intro-i div{
        font-size: 16px;
        font-weight: bold;
    }
    .intro-i p{
        font-size: 14px;
        padding-top: 4px;
    }
    .con-title{
        width: 100%;
        color: #333;
    }
    .con-title div{
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
    .con-title p{
        text-align: center;
    }
</style>