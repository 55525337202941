import { render, staticRenderFns } from "./xmall_detail.vue?vue&type=template&id=327a3203&scoped=true&"
import script from "./xmall_detail.vue?vue&type=script&lang=js&"
export * from "./xmall_detail.vue?vue&type=script&lang=js&"
import style0 from "./xmall_detail.vue?vue&type=style&index=0&id=327a3203&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327a3203",
  null
  
)

export default component.exports